<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter adj_sur">
      <div class="track_trace_adj">
        <div class="label_element_cover">
        <label>Enter</label>
        <span>
          <input
            type="text"
            placeholder="Search by Receipt No/Customer Ref/AWB No"
            title="Search by Receipt No/Customer Ref/AWB No"
            class="searchtext"
            v-model="receiptNo"
            @keyup.enter="searchReceiptNo"
            @keydown="nameKeydown($event)"
          />
          <!-- <button class="search-btn">
            <i class="material-icons">search</i>
          </button>-->
        </span>
        </div>
        <div class="label_element_cover">
        <label>Receiver</label>
        <span>
          <input
            type="text"
            placeholder="Enter receiver name"
            class="searchtext"
            v-model="deliveryName"
            @keyup.enter="searchDeliveryName"
             @keydown="nameKeydown($event)"
          />
          <!-- <button class="search-btn">
            <i class="material-icons">search</i>
          </button>-->
        </span>
        </div>
        <div class="label_element_cover">
        <label>Filter By Date</label>
        <flat-pickr
          class="date_for_pos_fixed"
          v-model="daterange"
          :config="configDate"
          placeholder="Date"
          name="birthdate"
          @on-close="dateChanged"
          @on-open="cleardatepicker"
        ></flat-pickr>
        </div>
        <div class="label_element_cover">
        <label>Filter By Status</label>
        <select v-model="status" @change="filterStatus">
          <option value disabled="disabled" selected="selected">Select status</option>
          <option value="all">All</option>
          <option value="Created">Created</option>
          <option value="Booked">Booked</option>
          <option value="Delivered">Delivered</option>
          <option value="Dispatched">Dispatched</option>
          <option value="Not Home">Not Home</option>
          <option value="PickedUp">PickedUp</option>
          <option value="Received">Received</option>
          <option value="RTS">RTS</option>
          <option value="Transmitted">Transmitted</option>
          <option value="WC">WC</option>
          <option value="Error">Error</option>
          <option value="Label Generated">Label Generated</option>
          <option value="Manifested">Manifested</option>
        </select>
        </div>
      </div>
    </div>
    <loader v-if="loading" />
    <section
      class="yellow_tables_list print_hide"
      v-if="!loading && show_data && courier.length > 0"
    >
      <div class="yellow_tables_wrapepr" v-for="(data,i) in courier" :key="i">
        <div class="two_buttons_container">
          <p class="bt bt_black">{{data.status}}</p>
          <p class="bt bt_black">{{data.status_date}} {{data.status_time}}</p>
          <a class="bt_print" @click="searchTrack(data)">Track</a>
          <a class="bt_print" @click="searchConsignment(data)" v-if="data.booking_type != 'Transport' ">Receipt</a>
          <a class="bt_print" @click="searchConsignmentTransport(data)" v-else>Receipt</a>

          <a class="bt_print" v-if="data.airway_bill && (data.file_name != null) " :href="data.label_url" target="_blank">Print Label</a>
        </div>

        <div class="view_consignment_wrapper" v-if="data.booking_type != 'Transport' ">
          <section class="table_row row1">
            <div class="div_33">
              <img class="logo_img" src="../../assets/temp_logo_yellow_new.png" />
              <div class="under_logo_transmit">
                <header class="small_header">CARRIER TRACKING NO</header>
                <div v-if="(data.booking_type != 'Batch Upload') && (data.airway_bill == data.booking_id)" class="small_body">{{data.booking_prefix}}{{data.airway_bill}}</div>
               <div v-else class="small_body">{{data.airway_bill}}</div> 
            </div>
            </div>
            <div class="div_33">
              <div class="middle_div">
                <div class="double_contents">
                  <header class="small_header">DATE</header>
                  <div class="small_body">{{data.created_at | formatDate}}</div>
                  <header class="small_header">BRANCH</header>
                  <div class="small_body">{{data.branch.branch_id}}</div>
                </div>
                <div class="double_contents">
                  <header class="small_header">PIECES</header>
                  <div class="small_body">{{data.item_qty}}</div>
                  <header class="small_header">WEIGHT</header>
                  <div class="small_body">{{data.item_weight}}</div>
                </div>
              </div>
            </div>
            <div class="div_33">
              <header class="small_header">TRACKING NUMBER</header>
              <div class="small_body small_body_2">
                <barcode tag="img" :value="data.booking_prefix + data.booking_id" />
              </div>
            </div>
          </section>
          <section class="table_row row2">
            <div class="div_50">
              <header class="small_header">Sender/Pickup Address</header>
              <div class="small_body">
                <div class="single_row">
                  <div>
                    {{data.pickup_address_1 ? data.pickup_address_1 + ', ':''}}
                    {{data.pickup_address_2 ? data.pickup_address_2 + ', ':""}}
                    {{data.pickup_city}}
                  </div>
                  <p><b>SHIPPER REF:{{data.reference_no}}</b></p>
                </div>
              </div>
              <div class="small_body address">E-mail: info@dbsa.asia</div>
            </div>
            <div class="div_50">
              <header class="small_header">Receiver/Drop Address</header>
              <div class="small_body">
                <div class="single_row">
                  <div>
                    {{data.delivery_name}},
                    {{data.delivery_address_1 ? data.delivery_address_1 + ', ':''}}
                    {{data.delivery_address_2 ? data.delivery_address_2 + ', ':""}}
                    {{data.delivery_city}}
                    </div>
                </div>
              </div>
              <div class="small_body address">Web: http://www.dbsa.asia</div>
            </div>
          </section>
        </div>


        <div class="view_consignment_wrapper" v-else>
            <section class="table_row row1">
              <div class="div_33">
                <img class="logo_img" src="../../assets/temp_logo_yellow_new.png" />
                <div class="under_logo_transmit">
                  <header class="small_header">VEHICLE NAME</header>
                  <div class="small_body">{{data.transport.vehicle_model}}</div>
                </div>
              </div>
              <div class="div_33">
                <div class="middle_div">
                  <div class="double_contents">
                    <header class="small_header">REQUEST DATE</header>
                    <div class="small_body">{{moment(data.request_date,"YYYY-MM-DD").format("MM-DD-YYYY")}}</div>
                    <header class="small_header">VEHICLE TYPE</header>
                    <div class="small_body" v-if="data.delivery_service== 'Shuttle'">Hatch Back</div>
                    <div class="small_body" v-if="data.delivery_service== 'VIP'">Sedan</div>
                    <div class="small_body" v-if="data.delivery_service== 'Limousine'">SUV </div>
                  </div>
                  <div class="double_contents">
                    <header class="small_header">PASSENGERS</header>
                    <div class="small_body">{{data.transport.no_of_passengers}}</div>
                    <header class="small_header">JOURNEY TYPE</header>
                    <div class="small_body">{{data.transport.trip_type}}</div>
                  </div>
                </div>
              </div>
              <div class="div_33">
                <header class="small_header">BOOKING NUMBER</header>
                <div class="small_body small_body_2">
                  <barcode tag="img" :value="data.booking_prefix + data.booking_id" />
                </div>
              </div>
            </section>
            <section class="table_row row2 pr">
              <div class="div_50">
                <header class="small_header">Sender/Pickup Address</header>
                <div class="small_body">
                  <div class="single_row">
                    <div>
                    {{data.pickup_address_1 ? data.pickup_address_1 + ', ':''}}
                    {{data.pickup_address_2 ? data.pickup_address_2 + ', ':""}}
                    {{data.pickup_city}}
                  </div>
                    <!-- <div>SHIPPER REF:{{data.reference_no}}</div> -->
                  </div>
                </div>
                <div class="small_body address pr">E-mail: info@dbsa.asia</div>
              </div>
              <div class="div_50">
                <header class="small_header">Receiver/Drop Address</header>
                <div class="small_body">
                  <div class="single_row">
                    <div>
                      {{data.delivery_address_1 ? data.delivery_address_1 + ', ':''}}
                      {{data.delivery_address_2 ? data.delivery_address_2 + ', ':""}}
                      {{data.delivery_city}}
                    </div>
                  </div>
                </div>
                <div class="small_body address pr">Web: http://www.dbsa.asia</div>
              </div>
            </section>
          </div>

        

        <!-- </div> -->
      </div>
    </section>
    <paginate
      v-if="pages > 0"
      v-show="!loading"
      v-model="current_page"
      :page-count="pages"
      active-class="paginate-active"
      :click-handler="fetchCouriers"
      prev-link-class="paginate-previous"
      next-link-class="paginate-next"
      :prev-text="'Prev'"
      :next-text="'Next'"
      :container-class="'pagination'"
      :no-li-surround="true"
    ></paginate>
    <h4 v-if="!loading && show_data && courier.length == 0">No data found</h4>
    <div class="black-overlay" v-if="viewconsignmentpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignment
            @closeModal="viewconsignmentpopup_booking_id = ''"
            :booking_id="viewconsignmentpopup_booking_id"
            :booking_prefix="viewconsignmentpopup_booking_prefix"
            v-if="viewconsignmentpopup_booking_id"
          />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-if="viewconsignmenttransportpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignmentJourney
            @closeModal="viewconsignmenttransportpopup_booking_id = '' "
            :booking_id="viewconsignmenttransportpopup_booking_id"
            :booking_prefix="viewconsignmenttransportpopup_booking_prefix"
            v-if="viewconsignmenttransportpopup_booking_id"
          />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-if="tracktracepopup_booking_id">
      <div class="box-modal trackPops">
        <div class="body">
          <!-- Comment -->
          <TrackTracePopup
            @closeModal="tracktracepopup_booking_id = ''"
            :booking_id="tracktracepopup_booking_id"
            :booking_prefix="tracktracepopup_booking_prefix"
            v-if="tracktracepopup_booking_id"
          />
          <!-- Comment -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ViewConsignment from "./ViewConsignment.vue";
import ViewConsignmentJourney from "./ViewConsignmentJourney.vue";
import TrackTracePopup from "./TrackTracePopup.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
export default {
  name: "TrackTrace",
  components: {
    ViewConsignment,
    TrackTracePopup,
    flatPickr,
    ViewConsignmentJourney
  },
  data() {
    return {
      receiptNo: "",
      daterange: moment(new Date()).format('YYYY-MM-DD'),
      status: "",
      deliveryName: "",
      courier: [],
      pages: 0,
      configDate: {
        mode: "range",
       format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
      current_page: 0,
      show_data: false,
      loading: false,
      showViewConsignment: false,
      showTrackTracePopup: false,
      post_data: {
        value: "",
        statusfilter: "",
        searchDeliveryName: "",
        from:moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD')
      },
      tracktracepopup_booking_id: "",
      tracktracepopup_booking_prefix: "",
      viewconsignmentpopup_booking_id: "",
      viewconsignmentpopup_booking_prefix: "",
      viewconsignmenttransportpopup_booking_prefix: "",
      viewconsignmenttransportpopup_booking_id:""
    };
  },
	filters: {
		formatDate(value) {
			if (!value) {
				return;
			}

			return moment(String(value)).format('DD-MM-YYYY')
		},
	},
  created() {
    this.$emit("breadCrumbData", ["Track And Trace"]);
    if (localStorage.track_trace_filter) {
      let data = JSON.parse(localStorage.track_trace_filter);
      if (data.booking_id) {
        this.receiptNo = data.booking_id;
        this.searchReceiptNo(data.booking_id);
        localStorage.removeItem("track_trace_filter");
      }
    }

    this.booking_id = this.$route.query.booking_id;

    if(this.booking_id) {
      this.post_data.value = this.booking_id;
      this.fetchCouriers();
      this.receiptNo = this.booking_id;
      localStorage.removeItem("redirect_path");
      localStorage.removeItem("redirect_booking_id");
    }
  },
  methods: {
      nameKeydown(e) {
        if (/^\W$/.test(e.key) && e.keyCode!=32) {
        e.preventDefault();
      }
    },
    searchReceiptNo(receiptNo) {
      this.post_data.value = this.receiptNo;
      this.fetchCouriers();
    },
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
      this.fetchCouriers();
    },
    cleardatepicker(){

      this.daterange="";
    },
    filterStatus(status) {
      this.post_data.statusfilter = this.status;
      this.fetchCouriers();
    },
    searchDeliveryName(deliveryName) {
      this.post_data.searchDeliveryName = this.deliveryName;
      this.fetchCouriers();
    },
    fetchCouriers(page = 1) {
      this.current_page = page;
      this.show_data = true;
      this.loading = true;
      this.axios
        .post("/api/courier/" + page + "/listingAndFilter", this.post_data)
        .then(response => {
          console.log(response);
          if (response.data.status == "ok") {
            // this.courier = response.data.couriers.list;
            this.courier = response.data.couriers.list.map(element => {
              console.log(element);
              element.show_view_popup = false;
              element.label_url = element.airway_bill && element.file_name ? process.env.VUE_APP_API_URL + "/labels/" + element.file_name : "";
              
              return element;
            });
            this.pages = response.data.couriers.pages;
          }
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    searchTrack(booking) {
      this.tracktracepopup_booking_prefix = booking.booking_prefix;
      this.tracktracepopup_booking_id = booking.booking_id;
    },
    searchConsignment(booking) {
      this.viewconsignmentpopup_booking_prefix = booking.booking_prefix;
      this.viewconsignmentpopup_booking_id = booking.booking_id;
    },
    searchConsignmentTransport(booking) {
      this.viewconsignmenttransportpopup_booking_prefix = booking.booking_prefix;
      this.viewconsignmenttransportpopup_booking_id = booking.booking_id;
    },
      //change slip generated status  to label printed on button click
    statusChange(id)
    {
      this.axios.post("/api/draft/carrierlabel/"+id)
      .then(response => {
          this.fetchCouriers();
      })
      .catch(error =>{
          console.log(error);
      });
    }
  }
};
</script>
<style  lang="scss" >
.transmittal_slip_filter .track_trace_adj {
  display: flex;
}
.transmittal_slip_filter .track_trace_adj span {
  display: block;
  position: relative;
  button {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    background: none;
    right: 20px;
    top: 5px;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    i {
      display: inline;
      color: #fff;
      margin: 0;
      line-height: 0;
      font-size: 24px;
    }
  }
}
.transmittal_slip_filter .track_trace_adj .searchtext {
  width: 260px !important;
  margin-left: 10px;
}
section.yellow_tables_list {
  padding: 20px 0;
  .yellow_tables_wrapepr {
    width: 780px;
    height: auto;
    border: 10px solid #ddd;
    position: relative;
    .two_buttons_container {
      position: absolute;
      width: 120px;
      height: auto;

      right: -150px;
      top: 50%;
      margin-top: -50px;
      .bt_print {
        display: block;
        color: #000;
        background: #06A5ED;
        width: 120px;
        height: 30px;
        line-height: 30px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 5px;
        font-weight: 400;
      }
    }
    .view_consignment_wrapper section.table_row .div_33 .extra1 {
      height: 50px;
      background: Red;
    }
    .view_consignment_wrapper section.table_row.row2 {
      height: 190px;
    }
  }
}
.view_consignment_wrapper
  section.table_row.row1
  .under_logo_transmit
  .small_body {
  height: 28px;
}
.bt {
  text-align: center;
  line-height: 25px;
  border-radius: 4px;
  display: block;
  margin: 5px 0;
}
.bt_black {
  background: #063657;
  color: #fff;
}
@media screen and (max-width: 1600px) {
  .transmittal_slip_filter .track_trace_adj .searchtext {
    width: 200px !important;
    margin-left: 0;
  }
}

@media print {
  .black-overlay {
    background: #fff;
  }
  .consignment_header {
    display: none !important;
  }
  .black-overlay .box-modal {
    left: 0 !important;
    top: 0 !important;
    display: block !important;
    transform: scale(1) !important;
    position: relative !important;
    box-shadow: 0 0 0 0 !important;
    border: 0 !important;
  }
  .black-overlay .box-modal .body {
    height: 800px !important;
    border: 0 !important;
    background: #fff;
  }
}
@media print and (-ms-high-contrast: none) {
  .black-overlay .box-modal {
    /* Enter your style code */
    transform: scale(0.8) !important;
    left: -20px !important;
    top: -20px !important;
  }
}
</style>
<style scoped>
section.yellow_tables_list .yellow_tables_wrapepr .view_consignment_wrapper section.table_row.row2{height:auto}
section.yellow_tables_list .yellow_tables_wrapepr .view_consignment_wrapper section.table_row.row2.pr{height:auto}
.view_consignment_wrapper section.table_row.row2 .small_body.address.pr{height:auto}
.view_consignment_wrapper section.table_row.row2 .small_body.address{height:auto}
.view_consignment_wrapper section.table_row.row2 .small_body{height:100px; }

</style>
